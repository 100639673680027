<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col
          cols="6"
          md="3"
          class="d-flex align-center justify-center avatar"
        >
          <div class="">
            <v-avatar size="10rem" v-if="Student.avatar">
              <v-img
                :src="`${Student.avatar}`"
                class="rounded admissionIdPicture"
                height="100%"
                width="100%"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            <v-icon color="black" size="20rem" v-else class="avatar-svg"
              >mdi-account-tie</v-icon
            >
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <p class="mb-2 text-justify">
            <span class="bold">Nom :</span>
            <span class="ml-1">{{ Student.lastName }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Prénoms :</span>
            <span class="ml-1">{{ Student.firstName }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Date d'anniversaire:</span>
            <span class="ml-1">{{ Student.birthday }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Lieu de naissance :</span>
            <span class="ml-1">{{ Student.birthPlace }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Nationalité :</span>
            <span class="ml-1">{{ Student.nationality }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Pays :</span>
            <span class="ml-1">{{ Student.country }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Ville :</span>
            <span class="ml-1">{{ Student.city }}</span>
          </p>

          <p class="mb-2 text-justify">
            <span class="bold">Téléphone :</span>
            <span class="ml-1">{{ Student.phone }}</span>
          </p>
          <p class="mb-2 text-justify">
            <span class="bold">Adress:</span>
            <span class="ml-1">{{ Student.address }}</span>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <p class="mb-1">
              <span>Option :</span>
              <span class="bold">{{ Student.department.name }}</span>
            </p>
            <p class="mb-1">
              <span>Niveau :</span>
              <span class="bold">{{ Student.currentLevel }}</span>
            </p>
          </div>
          <div class="mt-4">
            <p v-if="Student.godfather" class="mb-1">
              <span>Parain :</span>
              <span class="bold">{{ Student.godfather.fullname }}</span>
            </p>
          </div>
        </v-col>
      </v-row>

      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <v-row>
            <v-col cols="12" md="3">
              <h2 class="primary--text">Dettes standard</h2>
            </v-col>

            <v-col cols="12" md="3">
              <h2>Total : {{ Student.totalAmountStandardDebts }} FCFA</h2>
            </v-col>
            <v-col cols="12" md="3">
              <h2>Reste : {{ Student.totalLeftStandardDebts }} FCFA</h2>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="addStandardDebt"
                :disabled="Student.totalLeftStandardDebts <= 0"
                class="green white--text rounded d-none"
              >
                Ajouter une dette standard
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of Student.standardDebts"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <h3>{{ item.standardFees.name }}</h3>
              <p class="mb-1">
                <span>Montant :</span>
                <span class="primary--text"> {{ item.amount }} FCFA</span>
              </p>
              <p>
                <span>Reste :</span>
                <span class="primary--text">{{ item.left }} FCFA</span>
              </p>
              <p v-if="item.percentageReduction > 0">
                <span>Reduction :</span>
                <span class="primary--text"
                  >{{ item.percentageReduction }} %</span
                >
              </p>

              <div>
                <div>
                  <h4>Détails des paiements :</h4>
                  <ul>
                    <li v-for="(payment, i) of item.payments" :key="i">
                      <div class="px-3">
                        <div class="d-flex justify-space-between">
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Montant : </span>
                            <span class="text-caption"
                              >{{ payment.amount }} FCFA</span
                            >
                          </p>
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Date : </span>
                            <span class="text-caption">{{
                              payment.createdAtTime
                            }}</span>
                          </p>
                        </div>
                        <div class="d-flex justify-space-between">
                          <p
                            v-if="payment.createdBy"
                            class="text-subtitle-2 mb-0"
                          >
                            <span class="">Enregistrer par : </span>
                            <span class="text-caption"
                              >{{ payment.createdBy.lastName }}
                              {{ payment.createdBy.firstName }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="my-5 container">
        <div class="py-5 mt-5 mb-12">
          <v-row>
            <v-col cols="12" md="9">
              <h2 class="primary--text">Dettes additionels</h2>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn
                @click="addAdditionalDebt"
                class="green white--text rounded"
              >
                Ajouter une dette additionel
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col
            class=""
            v-for="(item, i) of Student.additionalDebts"
            :key="i"
            cols="12"
            md="4"
          >
            <div class="rounded elevation-1 py-4 px-2">
              <h3>{{ item.additionalCoast.name }}</h3>
              <p class="mb-1">
                <span>Montant :</span>
                <span class="primary--text"> {{ item.amount }} FCFA</span>
              </p>
              <p>
                <span>Reste :</span>
                <span class="primary--text">{{ item.left }} FCFA</span>
              </p>

              <div>
                <div>
                  <div class="d-flex justify-space-between">
                    <h4 class="mb-5">Détails des paiements</h4>
                  </div>
                  <ul>
                    <li v-for="(payment, i) of item.payments" :key="i">
                      <div class="px-3">
                        <div class="d-flex justify-space-between">
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Montant : </span>
                            <span class="text-caption"
                              >{{ payment.amount }} FCFA</span
                            >
                          </p>
                          <p class="text-subtitle-2 mb-0">
                            <span class="">Date : </span>
                            <span class="text-caption">{{
                              payment.createdAtTime
                            }}</span>
                          </p>
                        </div>
                        <div class="d-flex justify-space-between">
                          <p
                            v-if="payment.createdBy"
                            class="text-subtitle-2 mb-0"
                          >
                            <span class="">Enregistrer par : </span>
                            <span class="text-caption"
                              >{{ payment.createdBy.lastName }}
                              {{ payment.createdBy.firstName }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog
      v-model="standardFeesEditorDialog"
      max-width="1000px"
      class="black"
    >
      <editor-x
        :fields="standardFeesfields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>

    <v-dialog
      v-model="additionelCoastEditorDialog"
      max-width="1000px"
      class="black"
    >
      <editor-x
        :fields="additionelCoastfields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { addStandardDebt, addAdditionalDebt, deleteFaculty } from "@/api/user";
import { rules } from "@/utils/constant.js";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";

export default {
  name: "Student",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    rules,
    headers: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
      {
        text: "Numéro matricule",
        align: "start",
        sortable: true,
        value: "registrationNumber",
      },
      {
        text: "Nom",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Prénoms",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Options",
        align: "start",
        sortable: true,
        value: "departmentName",
      },
      {
        text: "Niveau",
        align: "start",
        sortable: true,
        value: "translatedLevel",
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    standardFeesEditorDialog: false,
    additionelCoastEditorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "Student",
      "Schools",
      "UserProfile",
      "StandardFees",
      "AdditionalCoasts",
    ]),
    standardFeesfields() {
      return [
        {
          name: "standardFees",
          label: "Dette standard",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getAdditionalAndStandardFees,
              params: {},
              getter: this.StandardFees,
              fieldName: "name",
            },
          },
        },
        {
          name: "student",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
    additionelCoastfields() {
      return [
        {
          name: "additionalCoast",
          label: "Dette additionel",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getAdditionalAndStandardFees,
              params: {},
              getter: this.AdditionalCoasts,
              fieldName: "name",
            },
          },
        },
        {
          name: "student",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions([
      "getStudent",
      "getUserProfile",
      "getAdditionalAndStandardFees",
    ]),
    closeForm() {
      this.Model = {};
      this.standardFeesEditorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getStudent({
          id: this.$route.params.id,
        });

        await this.getAdditionalAndStandardFees({});

        await this.getUserProfile();

        this.standardFeesEditorDialog = false;
        this.additionelCoastEditorDialog = false;
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les données")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.standardFeesEditorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addStandardDebt() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addStandardDebt;
      this.standardFeesEditorDialog = true;
    },
    addAdditionalDebt() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addAdditionalDebt;
      this.additionelCoastEditorDialog = true;
    },
    paidAdditionalCoast(item) {
      this.editedIndex = -1;
      this.Model = {
        _id: item._id,
      };
      this.callBack = addAdditionalDebt;
      this.standardFeesEditorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.standardFeesEditorDialog = true;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
  },
};
</script>

<style >
</style>
